import React, { useState } from "react"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { StyledButton } from "../styledComponents/button"
import { StyledOverflowOuter, StyledPricingList, StyledPricingListItem, StyledPricingListItemLabel, StyledPricingListItemList, StyledPricingListItemListItem, StyledPricingListItemPrice, StyledPricingListItemPriceCurrency, StyledPerTimeframe, StyledPricingListItemPriceFull, StyledPricingListItemPriceSale } from "../styledComponents/pricingTier"
import { StyledTootTip, StyledToolTipData } from "../styledComponents/base"
import Icon from "./icon"

const PractitonerPricingList = ({ billed }) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <StyledPricingList>
                <StyledPricingListItem itemType="https://schema.org/Product" itemScope>
                    <StyledPricingListItemLabel>
                        Web Starter
                    </StyledPricingListItemLabel>
                    <StyledPricingListItemPrice>
                        <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
                            <meta itemprop="lowPrice" content="49.99" />
                            <meta itemprop="highPrice" content="99.99" />
                            <meta itemprop="priceCurrency" content="GBP" />
                            <StyledPricingListItemPriceCurrency>&pound;</StyledPricingListItemPriceCurrency><StyledPricingListItemPriceSale>{billed == 'monthly' ? '49.99' : '499'}</StyledPricingListItemPriceSale>
                            <StyledPricingListItemPriceFull><StyledPricingListItemPriceCurrency className="nostrike">&pound;</StyledPricingListItemPriceCurrency><span>{billed == 'monthly' ? '99.99' : '999.99'}</span></StyledPricingListItemPriceFull>
                        </div>
                    </StyledPricingListItemPrice>
                    <h3>Whats included:</h3>
                    <StyledOverflowOuter>
                        <StyledPricingListItemList>
                            {/* <StyledPricingListItemListItem>Your own free domain name for 1 year</StyledPricingListItemListItem> */}
                            <StyledPricingListItemListItem>Upto 5 email addresses</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>A responsve website for mobile, tablet and desktop<StyledTootTip id="responsive"><Icon name="tooltip-info" /></StyledTootTip>
                                <ReactTooltip
                                    anchorId="responsive"
                                    place="bottom"
                                    content="Websites that adapt to all screen sizes and resolutions, not only on desktop but also on mobile, tablet and sometimes even TV."
                                />
                            </StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>Content Management System - edit your webpages &amp; add content<StyledTootTip id="cms"><Icon name="tooltip-info" /></StyledTootTip>
                                <ReactTooltip
                                    anchorId="cms"
                                    place="bottom"
                                    content="A content management system (CMS) is the ultimate solution for website owners who want to take control of their online content without any technical hassle."
                                />
                            </StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>Add upcoming events &amp; list them on your own website</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>Publish unlimited blog posts</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>Web Standards Compliant (W3C)</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>A fully accessible site<StyledTootTip id="accessibility"><Icon name="tooltip-info" /></StyledTootTip>
                                <ReactTooltip
                                    anchorId="accessibility"
                                    place="bottom"
                                    content="The Web Content Accessibility Guidelines (WCAG) are part of a series of web accessibility guidelines published by the Web Accessibility Initiative (WAI) of the World Wide Web Consortium (W3C), the main international standards organization for the Internet."
                                />
                            </StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>1 x Contact form</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>Add your social media links	</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>Training support</StyledPricingListItemListItem>
                            <StyledPricingListItemListItem>1 year free hosting worth £89</StyledPricingListItemListItem>
                        </StyledPricingListItemList>
                    </StyledOverflowOuter>
                    <StyledButton>Get started</StyledButton>

                    <div itemtype="https://schema.org/Product" itemscope>
                        <meta itemprop="name" content="Practitioner Professional Web Starter" />
                        <meta itemprop="description" content="" />
                        <div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                            <meta itemprop="reviewCount" content="89" />
                            <meta itemprop="ratingValue" content="4.4" />
                        </div>
                        <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                            <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                                <meta itemprop="name" content="Fred Benson" />
                            </div>
                            <div itemprop="reviewRating" itemtype="https://schema.org/Rating" itemscope>
                                <meta itemprop="ratingValue" content="4" />
                                <meta itemprop="bestRating" content="5" />
                            </div>
                        </div>
                    </div>
                </StyledPricingListItem>

                <StyledPricingListItem>
                    <StyledPricingListItemLabel>
                        Web Starter +eCommerce
                    </StyledPricingListItemLabel>
                    <StyledPricingListItemPrice>
                        <StyledPricingListItemPriceCurrency>&pound;</StyledPricingListItemPriceCurrency>{billed == 'monthly' ? '49.99' : '419.99'}
                    </StyledPricingListItemPrice>
                    <h3>Whats included:</h3>
                    <p>Everything in the <strong>Web Starter</strong> plus the following:</p>
                    <StyledPricingListItemList>
                        <StyledPricingListItemListItem>Easily take payments via Stripe the leading online payment processing platform. </StyledPricingListItemListItem>
                        <StyledPricingListItemListItem>Appointment bookings with payment integration</StyledPricingListItemListItem>
                        <StyledPricingListItemListItem>Sell your workshops online </StyledPricingListItemListItem>
                        <StyledPricingListItemListItem>Sell products online</StyledPricingListItemListItem>
                    </StyledPricingListItemList>
                    <StyledButton>Get started</StyledButton>
                </StyledPricingListItem>
                <StyledPricingListItem>
                    <StyledPricingListItemLabel>
                        Web Starter +eCommerce + MailChimp
                    </StyledPricingListItemLabel>
                    <StyledPricingListItemPrice>
                        <StyledPricingListItemPriceCurrency>&pound;</StyledPricingListItemPriceCurrency>{billed == 'monthly' ? '79.99' : '671.91'}
                    </StyledPricingListItemPrice>
                    <h3>What's included:</h3>
                    <p>Everything in the <strong>Web Starter</strong> plus the following:</p>
                    <StyledPricingListItemList>
                        <StyledPricingListItemListItem>Newsletter signup</StyledPricingListItemListItem>
                        <StyledPricingListItemListItem>Send eNewsletters with Mailchimp free package (1000 monthly emails)</StyledPricingListItemListItem>
                    </StyledPricingListItemList>
                    <StyledButton>Get started</StyledButton>
                </StyledPricingListItem>
            </StyledPricingList>

        </>

    )
}

export default PractitonerPricingList