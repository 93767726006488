import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"

const StyledPromoList = styled.ul`
    padding: 0;
    li {
        ${baseGridStyles}
        padding: 0;
        margin-bottom: 3.2rem;

        div.gatsby-image-wrapper,
        div.promoText {
            grid-column: span 4;
        }

        &:after {
            @media screen and (min-width: ${breakpoints.sm}px) {
                content: " ";
                display: block;
                width: 100%;
                height: 3px;
                background: var(--grey-lightest);
                margin-top: 1.2rem;
                margin: 1.2rem auto 0;
                grid-column: 1 / 7;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 1 / 12;
            }
            @media screen and (min-width: ${breakpoints.l}px) {
                grid-column: 1 / 13;
            }
        }

        &:last-child:after {
            background: none;
        }
        div.gatsby-image-wrapper {
            margin: 0;
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: 1 / 6;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 1 / 6;
            }
            @media screen and (min-width: ${breakpoints.l}px) {
                grid-column: 1 / 5;
            }
        }

        div.promoText {
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: 1 / 7;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 4 / 13;
            }
            @media screen and (min-width: ${breakpoints.l}px) {
                grid-column: 5 / 13;
            }

            &:last-child {
                background: var(--white);
                padding-left: 3rem;
                padding-right: 3rem;
                padding-bottom: 4rem;
                border-radius: 2rem;
            }
        }
        
    }
`

export {
    StyledPromoList
}