import React, {  useState } from "react"
import { ModalProvider } from "styled-react-modal"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { StyledPractitonerPricingTable, StyledPractitonerPricingCustomWebsite } from "../styledComponents/pricingTier"
import PractitonerPricingList from "./PractitonerPricingList"
import { FadingBackground } from "../components/modalFadingBackground"
import Modal from "../components/modal"  

const PractitonerPricing = () => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <StyledPractitonerPricingTable>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    <Tab>Billed Monthly</Tab>
                    <Tab>Billed Annually</Tab>
                </TabList>
                <TabPanel>
                    <PractitonerPricingList billed="monthly" />
                </TabPanel>
                <TabPanel>
                    <PractitonerPricingList billed="annually" />
                </TabPanel>
            </Tabs>
            <StyledPractitonerPricingCustomWebsite>
                <p className="customBuild">Custom website? or want something bespoke to your requirements? please get on contact and we can have a chat.</p>
                <ModalProvider backgroundComponent={FadingBackground}>
                    <Modal 
                      title="Get in contact with us" 
                      serviceName={false}
                      buttonLabel="Chat with us"
                      buttonStyle="HeroButton"
                      formExcerpt="Please fill in the following form and one of our experts will run a report and get back to you within 48 hours."
                    />
                  </ModalProvider>
            </StyledPractitonerPricingCustomWebsite>
            
        </StyledPractitonerPricingTable>

    )
}

export default PractitonerPricing