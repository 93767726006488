import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleH1, ArticleHeader, ArticleSection } from "../../styledComponents/article"
import { HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import { HeroFullScreenImageWrapper } from "../../styledComponents/heroFullscreenImage"
import {
    Section
} from "../../styledComponents/section"
import PractitonerList from "../../components/practitonerList"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import PractitonerPricing from "../../components/pricingTable"
import { StyledPromoList } from "../../styledComponents/promoList"


const HealthPractitioners = ({ data, location, createCookie }) => {

    return (
        <Layout location={location} hasFullScreenHero>
            <Seo
                title="The Internet Consultancy Group"
                description=""
                image=""
                url=""
            />
            <HeroFullScreenImageWrapper backgroundColor="var(--dark-blue-mid)" gridRowGap="0">
                <StaticImage
                    loading="eager"
                    alt=""
                    backgroundcolor="#002F4A"
                    layout="fullWidth"
                    src={
                        "../../images/health-practitioner-min.jpg"
                    }
                    formats={["auto", "webp", "avif"]}
                />
                <HeroText fontColor="var(--white)" isFullScreenImage>
                    <HeroHeader level={1} fontColor="var(--white)">
                        Helping Quantum-Touch practitioners online.
                    </HeroHeader>
                    <HeroDescription>Do you want a professional website. Without the Price. That is incredibly easy to use.<br /><br /> <br /><br /> </HeroDescription>
                </HeroText>
            </HeroFullScreenImageWrapper>
            <Article
                itemScope
                itemType="http://schema.org/Article"
                style={{ position: 'relative', zIndex: '1' }}
                servicesList
            >
                <ArticleHeader>
                    <ArticleH1 align="center" itemProp="headline">Helping spread the wonderful energy of Quantum-Touch.</ArticleH1>
                    <p>List your Quantum-Touch Workshops, Share your testimonials, gain new customers and improve your online presence.</p>
                </ArticleHeader>
                <ArticleSection isServicesList>
                    <PractitonerList isHomePage />
                </ArticleSection>
            </Article>

            <Section background="var(--mid-blue-mid)" type="pullout-with-image">
                <h2>According to Google, <strong>61% of users are unlikely</strong> to return to a site on mobile if they had trouble accessing it and <strong>40% visit a competitor's site instead</strong></h2>
                <StaticImage
                    className="promoImage"
                    loading="eager"
                    alt=""
                    // layout="fixed"
                    src={
                        "../../images/good-bad-mobile-ux.png"
                    }
                    formats={["auto", "webp", "avif"]}
                />
            </Section>

            <Article style={{ position: 'relative', zIndex: '1' }}>
                <ArticleHeader>
                    <ArticleH1 align="center" itemProp="headline">Integrated Services</ArticleH1>
                    <p>As part of this offering we will be integrate the following professional services.</p>
                </ArticleHeader>
                <ArticleSection isServicesList>
                    <StyledPromoList>
                        <li><StaticImage
                            className="promoImage"
                            loading="eager"
                            alt=""
                            layout="fixed"
                            src={
                                "../../images/stripe-payments.png"
                            }
                            formats={["auto", "webp", "avif"]}
                        /> <div className="promoText"><p>Stripe is a certified PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry. They use the best-in-class security tools and practices to maintain a high level of security at Stripe.</p></div></li>
                        <li><StaticImage
                            className="promoImage"
                            loading="eager"
                            alt=""
                            layout="fixed"
                            src={
                                "../../images/wordpress-logo.png"
                            }
                            formats={["auto", "webp", "avif"]}
                        /> <div className="promoText"><p>WordPress is a great choice for managing a website's content, even for those without technical expertise. Its user-friendly interface makes it easy to create and publish posts, pages and other content.</p>
                                <p>WordPress is a flexible, accessible and cost-effective solution for managing a website's content.</p></div></li>
                                <li><StaticImage
                            className="promoImage"
                            loading="eager"
                            layout="fixed"
                            src={
                                "../../images/mailchimp.png"
                            }
                            formats={["auto", "webp", "avif"]}
                        />  <div className="promoText"><p>Empower your marketing efforts with Mailchimp! Say goodbye to complicated and time-consuming email marketing and hello to effortless, effective campaigns.</p>
                                <p>We can set your template up for you to run your own campaing. Automatically send your potential customers a welcome email to introduce yourself or offer a special promotion.</p>
                                <p>Send upto 100 emails monthly </p></div></li>
                    </StyledPromoList>
                </ArticleSection>

            </Article>


            <div style={{ position: 'relative', marginTop: '-22rem', top: '0.3rem' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="250">
                    <g fill="none">
                        <path fill="#E15D0F" d="M0 82.044c128-14.796 384-71.581 640-73.98 256-2.4 341.147 64.404 576.697 51.671 30.581-1.653 226.138-20.095 243.822-19.958C1698.222 41.615 1800.863 94.07 1920 81.044V250H0V82.044Z" />
                        <path fill="#CD4C00" d="M0 246.004c50.012-14.587 150.037-61.545 250.061-72.934 100.025-11.39 150.037 19.182 250.061 15.985 100.025-3.197 150.037-35.368 250.061-31.97 100.025 3.396 150.037 54.35 250.061 48.955 100.025-5.395 150.037-72.934 250.062-75.931 100.024-2.998 150.522 56.948 250.06 60.944 99.54 3.997 181.334-25.776 230.86-33.969 21.117-1.022 35.303-1.022 42.559 0 27.735 3.907 39.995 12.342 47.044 15.986 28.408 14.686 61.465 40.33 99.171 76.93-122.966-.602-329.079-.602-618.337 0S578.517 250.602 0 250v-3.996Z" />
                    </g>
                </svg>
            </div>
            <Article
                itemScope
                itemType="http://schema.org/Article"
                background="background:var(--orange-mid)"
            >
                <ArticleHeader color="color:var(--white)">
                    <ArticleH1 align="center" color="color:var(--white)" itemProp="headline">Pricing</ArticleH1>
                    <p>List your Quantum-Touch Workshops, Share your testimonials, gain new customers and improve your online presence.</p>
                </ArticleHeader>
                <ArticleSection isServicesList>
                    <PractitonerPricing />

                </ArticleSection>

            </Article>

            <Article type="faq">
                <ArticleHeader type="faq">
                    <h2 itemProp="headline" noMargin><abbr title="Frequently Asked Questions">FAQ's</abbr></h2>
                </ArticleHeader>
                <ArticleSection>
                    <Accordion allowMultipleExpanded="true">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    I already have a website, can I move my website over?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Exercitation in fugiat est ut ad ea cupidatat ut in
                                    cupidatat occaecat ut occaecat consequat est minim minim
                                    esse tempor laborum consequat esse adipisicing eu
                                    reprehenderit enim.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    I already have hosting can I move my website over?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    In ad velit in ex nostrud dolore cupidatat consectetur
                                    ea in ut nostrud velit in irure cillum tempor laboris
                                    sed adipisicing eu esse duis nulla non.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Can I upload online & in person events?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Intentional and strategic user experience has the potential to raise conversion rates by as much as 400 percent. (Source: Forrester)
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Is this only for Quantum-Touch practitioners & instructors?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Usability testing is a way to see how easy to use something is by testing it with real users. Users are asked to complete tasks, typically while they are being observed by a researcher, to see where they encounter problems and experience confusion. If more people encounter similar problems, recommendations will be made to overcome these usability issues.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Why is this so cheap?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Usability testing is a way to see how easy to use something is by testing it with real users. Users are asked to complete tasks, typically while they are being observed by a researcher, to see where they encounter problems and experience confusion. If more people encounter similar problems, recommendations will be made to overcome these usability issues.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    How does the compare to Wix, SquareSpace or Weebly?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Usability testing is a way to see how easy to use something is by testing it with real users. Users are asked to complete tasks, typically while they are being observed by a researcher, to see where they encounter problems and experience confusion. If more people encounter similar problems, recommendations will be made to overcome these usability issues.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </ArticleSection>
            </Article>



        </Layout>
    )
}

export default HealthPractitioners
