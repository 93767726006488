import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"

const HeroFullScreenImageWrapper = styled.div`
    ${baseGridStyles}
    position: relative;
    min-height: 88vh;
    background: rgb(0,0,0);

    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 3rem 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) { 
        max-width: 100vw;
        height: 80vh;
        ${props => props.gridRowGap && 'grid-row-gap:'+ props.gridRowGap  };
    }

    .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0rem;      
    }
`

export { HeroFullScreenImageWrapper }