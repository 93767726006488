import styled from "styled-components"
import { baseGridStyles, strikethrough } from "./base"
import breakpoints from "./breakpoints"
import { bold16,  bold20,  bold28, regular14, regular16, regular18, regular20 } from "../styledComponents/typography"
const StyledPractitonerPricingTable = styled.div`

p.customBuild { 
    color: var(--white);
    margin: 0 auto;
    width: 100%;
    text-align: center;

}
    ul.react-tabs__tab-list {
        justify-content: center;

        li {

        }
    }
`

const StyledOverflowOuter = styled.div`
    
  }
`
const StyledPricingList = styled.ul`
	list-style-type: none;
    ${baseGridStyles}
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${breakpoints.sm}px) {
        margin-bottom: 0rem;
        padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0
    }

    ul {
            li {
            list-style-type: none;
            margin-bottom: 0.4rem;
            @media screen and (min-width: ${breakpoints.sm}px) {
                margin-bottom: 0.4rem;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                margin-bottom: 0.4rem;
            }
        }
    }
`

const StyledPricingListItem = styled.li`
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--grey-lighter);
    border-radius: 1.2rem;
    padding: 1.6rem;
    margin: 1.6rem 0;
    grid-column: span 4;
    background: var(--white);
    
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 2;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: span 4;
    }

    h3 {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        ${bold20}
        margin-left: 0;
    }
    p {
        margin: 0;
        @media screen and (min-width: ${breakpoints.md}px) {
            ${regular16}
            padding: 0 1.6rem;
        } 
    }

`

const StyledPricingListItemLabel = styled.span`
	text-transform: uppercase;
    text-align: center;
    width: 100%;
    ${regular14}

    &:after {
        content: " ";
        display: block;
        width: 120px;
        height: 3px;
        background: var(--light-blue-mid);
        margin-top: 1.2rem;
        margin: 1.2rem auto 0;
    }
`

const StyledPricingListItemPrice = styled.div`
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    padding-bottom: 1.6rem;
	${bold28}
    width: 100%;
    text-align: center;
    border-bottom: 2px solid var(--grey-light);
    
`

const StyledPricingListItemList = styled.ul`
	${regular16}
    margin: 1.6rem 0;
    padding-left: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        ${regular18}
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        ${regular20}
        padding: 0 1.6rem;
    }
    
`

const StyledPricingListItemListItem = styled.li`
	${regular16}
    position: relative;
    padding-left: 2rem;

    &:before { 
        content: '✓';
        color: green;
        margin: 0 0.6rem 0 -1.9rem;
    }
`

const StyledPricingListItemPriceCurrency = styled.span`
    ${bold16}
    position: relative;
    top: -1.2rem;
    left: -0.2rem;
`

const StyledPricingListItemPriceFull = styled.span`
    color: red;margin:0 2rem;
    span {
        ${strikethrough}        
    }
    span.nostrike {
        &:before {
            border-top: 0px solid;
          }
    }
`

const StyledPricingListItemPriceSale = styled.span`
`

const StyledPractitonerPricingCustomWebsite = styled.div`
    ${baseGridStyles}
    
    p {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
    }
    button {
        grid-column: span 4;
        margin: 0 auto;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: span 12;
        }
    }
`
const StyledPerTimeframe = styled.span`
    ${regular14}
`
export {
    StyledOverflowOuter,
    StyledPractitonerPricingTable,
    StyledPricingList,
    StyledPricingListItem,
    StyledPricingListItemLabel,
    StyledPricingListItemPrice,
    StyledPricingListItemList,
    StyledPricingListItemListItem,
    StyledPricingListItemPriceCurrency,
    StyledPractitonerPricingCustomWebsite,
    StyledPerTimeframe,
    StyledPricingListItemPriceFull,
    StyledPricingListItemPriceSale
}