import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { bold20, bold24, regular14, regular18 } from "./typography"


const ServicesList = styled.ul`
    list-style-type: none;
    margin: 8rem 0 0;
    padding: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        ${baseGridStyles}
        margin-top: 6rem;
        margin-bottom: 0rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        margin-top: 10rem;
        padding: 0
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        row-gap: 6rem;
    }
    
`

const ServicesListItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--grey-lighter);
    border-radius: 1.2rem;
    margin-bottom: 10rem;
    padding: 1.6rem;
    background: var(--grey-lightest);

    &:last-child {
        margin-bottom: 0rem;
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 3;
        margin: 0 2rem 12rem 2rem;
        &:first-of-type {
            ${props => props.isHomePage && 'grid-column: span 6;'}
            h2, p {
                max-width: 50rem;
            }
        }

        &:nth-last-of-type(-n+2) {
            margin-bottom: 2rem;
        }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 6;
        margin: 0 2rem 12rem 2rem;
        &:first-of-type {
            ${props => props.isHomePage && 'grid-column: span 12; grid-column-start: 4; grid-column-end: 10'}
            
        }
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: span 4;
        margin: 0 2rem 12rem 2rem;
        &:first-of-type {
            ${props => props.isHomePage && 'grid-column: span 4'}
        }
        &:nth-last-of-type(-n+3) {
            margin-bottom: 2rem;
        }
    }

    &:hover { 
        h2:after {
            content: " ";
            display: block;
            width: 160px;
            height: 3px;
            background: var(--orange-mid);
            margin-top: 1.2rem;
            margin: 1.2rem auto 0;
        }
    }
    a {
        text-align: center;
        max-width: 23rem;
    }
    svg {
        margin-top: -10rem;
    }

    h2, p {
        text-align:center;
    }
    h2 {
        ${regular14}
        font-weight: 700;
        text-transform: uppercase;
        @media screen and (min-width: ${breakpoints.md}px) {
            margin-top: 2.4rem;
            margin-bottom: 1.6rem;
        }

        &:after {
            content: " ";
            display: block;
            width: 160px;
            height: 3px;
            background: var(--light-blue-mid);
            margin-top: 1.2rem;
            margin: 1.2rem auto 0;
        }

    }
    h3 {
        ${bold20}
        text-align: center;
        @media screen and (min-width: ${breakpoints.md}px) {
            ${bold20}
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            ${bold24}
        }
    }
    p {
        margin-bottom: 1.6rem;
        @media screen and (min-width: ${breakpoints.md}px) {
            ${regular18}
        }

        &:last-of-type {
            margin-bottom: 1.6rem;
        }
    }
`

export {
    ServicesList,
    ServicesListItem
}